<template>
  <div :class="[className]">
    <el-dialog
      :title="title"
      :visible.sync="centerDialogVisible"
      :width="width"
      class="tishiDialog"
      :modal-append-to-body="false"
      :show-close="false"
      :close-on-click-modal="closeOnClickModal"
      :close-on-press-escape="closeOnPressEscape"
    >
      <template v-if="componentName">
        <template v-if="propsData">
          <div ref="componentCustom" />
        </template>
        <template v-else>
          <component :is="componentName" ref="componentCustom" @close="close($event)"/>
        </template>
      </template>
      <template v-else>
        <div :style="contentStyle" :class="[checkHtml(content)?'':'dialogContent']" v-html="content" />
      </template>
     <!-- <span slot="footer" class="dialog-footer">
        <el-button v-if="cancelText!=''" class="close_btn" @click="closeFn(1)">{{ cancelText }}</el-button>
        <el-button v-if="confirmText!=''" class="saves_btn" type="primary" @click="closeFn(2)">{{ confirmText }}</el-button>
      </span> -->
	  <span slot="footer"  class="dialog-footer">
	  	<el-button  class="base_but_style" v-if="cancelText!=''" @click="closeFn(1)">{{ cancelText }}</el-button>
	  	<el-button  class="base_but_style base_save_but" v-if="confirmText!=''" type="primary" @click="closeFn(2)">{{ confirmText }}</el-button>
	  </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      dialogFn: null, // 回调方法
      content: '', // 内容，可以是html
      title: '', // 标题
      width: '400px', // 宽度
      centerDialogVisible: false,
      cancelText: '', // 取消按钮文字，不传不显示取消按钮
      confirmText: '', // 确定按钮文字，不传不显示确定按钮
      contentStyle: { 'text-align': 'center' }, // 内容的基础样式
      showClose: false, // 是否显示关闭按钮
      btnClickType: 0,//点击按钮的判断，1：取消，2：确定，0：其他
      closeOnClickModal: false, // 是否可以通过点击 modal 关闭 Dialog
      closeOnPressEscape: false, // 是否可以通过按下 ESC 关闭 Dialog
      twiceTitle: '', // 确定关闭时的二次确定文字，不传不会二次提示
      className: '',//弹窗的close,
      componentName: null,
      propsData: null,//组件的参数
      componentCustomDom: null
    }
  },
  watch: {
    centerDialogVisible(value) {
      if (!value) {
        if (this.componentCustomDom) {
          this.componentCustomDom.$destroy()
        }
        if (this.btnClickType == 0) {
          this.dialogFn({ close: true })
        }
      }
    }
  },
  methods: {
    // 判断是否存在html
    checkHtml(htmlStr){
      var reg = /<[^>]+>/g
      return reg.test(htmlStr)
    },
    // 关闭按钮的操作，主要是二次确定需要的
    hideFn(cancel, type) {
      if (cancel) {
        this.closed = true
        this.centerDialogVisible = false
        this.btnClickType = type
        if (type == 1) {
          this.dialogFn({ cancel: true })
        } else if (type == 2) {
          this.dialogFn({ confirm: true })
        } else {
          console.log('close')
        }
      }
    },
    // 关闭弹窗
    closeFn(type) {
      if (this.twiceTitle != '' && type == 2) {
        this.$confirm(this.twiceTitle)
          .then(_ => {
            this.hideFn(true, type)
          })
          .catch(_ => {})
      } else {
        this.centerDialogVisible = false
        this.btnClickType = type
        if (type == 1) {
          this.dialogFn({ cancel: true })
        } else {
          this.dialogFn({ confirm: true })
        }
      }
    },
    close(type = 'confirm') {
      if (type == 'confirm') {
        this.closeFn(2)
      } else {
        this.closeFn(1)
      }
    },
    showDialog(obj) {
      Object.assign(this.$data, this.$options.data.call(this))
      if (obj.class) this.className = obj.class
      if (obj.title) this.title = obj.title
      if (obj.content) this.content = this.formatHtml(obj.content)
      if (obj.width) this.width = obj.width
      if (obj.cancelText) this.cancelText = obj.cancelText
      if (obj.confirmText) this.confirmText = obj.confirmText
      if (obj.style) this.contentStyle = obj.style
      if (obj.showClose) this.showClose = obj.showClose
      if (obj.twiceTitle) this.twiceTitle = obj.twiceTitle
      if (obj.closeOnClickModal) this.closeOnClickModal = obj.closeOnClickModal
      if (obj.closeOnPressEscape) this.closeOnPressEscape = obj.closeOnPressEscape
      if (obj.propsData) this.propsData = obj.propsData
      if (this.cancelText == '' && this.confirmText == '' && !obj.components) this.showClose = true
      this.centerDialogVisible = true
      this.dialogFn = obj.success || (() => {})
      // 挂载组件
      if (obj.components) {
        if (obj.propsData) {
           const ConfirmDialogCom = Vue.component('ConfirmSolt', obj.components)// 创建组件
 
          const componentsObject = {}
          // 这样内部的组件可以直接使用this.$router、this.$store、this.$parent及this.$root，否则需要单独引入（获取传入的componentsArray，然后再次挂载到ConfirmSolt组件上）
          // ConfirmSolt组件的parent就是当前组件的this,其他的就直接区当前组件实例上的
          this.componentsArray.forEach(item => {
            componentsObject[item] = item == 'parent' ? this : this[`$${item}`]
          })
 
          // propsData 是组件传参
          const confirmDialogComNew = new ConfirmDialogCom(Object.assign(componentsObject, { propsData: obj.propsData ? obj.propsData : {}}))// 创建组件实例（可以传递参数 { propsData: props }）
          confirmDialogComNew.$on('close', res => {
            this.close(res)
          })
          this.componentName = 'ConfirmSolt'
          this.$nextTick(() => {
            const comp = confirmDialogComNew.$mount(this.$refs.componentCustom)
            this.componentCustomDom = comp
          })
        } else {
          Vue.component('ConfirmSolt', obj.components)
          this.componentName = 'ConfirmSolt'
          this.$nextTick(() => {
            this.componentCustomDom = this.$refs.componentCustom
          })
        }
      }
    },
    // 实现可以显示html标签
    // this.content='`<div>测试显示div</div>`正常的文字'
     formatHtml(val) {
      const sing = '`'
      const regxd = RegExp(`${sing}<[^${sing}]+>${sing}`, 'g')
      val = val.replace(regxd, function(word) {
        if (/<[^<]+>/g.test(val)) { // 判断是否存在html标签
          const getHtml = (word) => {
            let wordString = word.replace(/^(\s|`)+|(\s|`)+$/g, '')// 清除前后`符号
            const htmlArray = []
            wordString.replace(/<\/[^<]+>/g, function(word1) { // 获取每个标签类型的结束标签，即存在/的标签，比如：</div>
              htmlArray.push(word1.replace(/^(\s|<\/)+|(\s|>)+$/g, ''))// 获取html里面存在的标签，并清除前<,后>
            })
            // 获取html标签以及中间的值
            const htmlText = []
            htmlArray.forEach(item => {
              const regX = RegExp(`<${item}[^<]+<\/${item}>`, 'g')
              console.log(regX)
              wordString.replace(regX, function(word2) {
                htmlText.push(word2)
              })
            })
            console.log(htmlText)
            htmlText.forEach(item => {
              var ele = document.createElement('span')
              ele.appendChild(document.createTextNode(item))
              wordString = wordString.replace(RegExp(item, 'g'), `<span class='codeHtml' style='display: inline-block;padding: 4px 2px;background-color: #fff5f5;color: #ff502c;border-radius: 2px;'>${ele.innerHTML}</span>`)
            })
            return wordString
          }
          return getHtml(word)
        } else {
          return word
        }
      })
      return val
    }
  }
}
</script>
<style lang="scss" scoped>
.dialogContent{
  line-height: 2;
}
.dialog-footer{
		// border-top: 1px solid #DCDFE6;
		display: block;
		padding-top: 20px;
		text-align: center;
	}
	::v-deep .el-dialog__title{
		font-size: 16px;
	}
	::v-deep .el-dialog__body{
			border-top: 1px solid #f9f9f9 !important;
			border-bottom: 1px solid #f9f9f9  !important;
			padding:40px 20px  !important;
		}
	::v-deep .el-dialog__header{
		padding-bottom: 20px !important;
	}
	::v-deep .el-dialog__footer{
		// border-top: 1px solid #f9f9f9;
		padding:0 0 20px 0 !important;
	}
::v-deep .el-dialog{
  margin-top:0 !important ;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 5px !important;
}
 
</style>